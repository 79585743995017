import Navbar from '../components/navbar';
import '../Styles/TonNéon.css';
import Footer from '../components/Footer'
import Information from '../components/Information';
import Sigle from "../components/Sigle"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { Helmet } from 'react-helmet';

function Nous() {
    return (
        <div className="App">
            <Helmet>
                <title>Nous</title>
                <meta name="description" content="Créer votre propre néon"/>
            </Helmet>
            <Navbar/>
            <Sigle/>
            <h1 className='Title'><u>Qui Sommes Nous</u></h1>
            <Information/>
            <Footer/>
            <SpeedInsights/>
            <Analytics/>
        </div>
    );
}

export default Nous;