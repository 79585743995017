import { FaBars, FaTimes } from 'react-icons/fa';
import { useRef } from 'react';
import "../Styles/nav.css";
import logo from "../assets/13.webp";

function Navbar() {
    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    return (
        <header>
            <img className="logo" src={logo} alt="logo"/>
            <nav ref={navRef}>
                <a href="/" >Accueil</a>
                <a href="/Ton_Néon_sur_messure" >Néon Sur Mesure</a>
                <a href="/Ton_Néon_personaliser" >Néon Personnalisé</a>
                <a href="/Nos_classique" >Nos Classiques</a>
                <a href="/Contact" >Contact</a>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes/>
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
                <FaBars/>
            </button>
        </header>
    );
}

export default Navbar;