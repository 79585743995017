import "../Styles/Creation.css"
import "../Styles/font.css"
import videomp4 from "../assets/video/video site.mp4"
import videogg from "../assets/video/video-site.ogg"
import img1 from '../assets/visu 1.png'
import img2 from '../assets/visu 2.png'

function Creation() {
    return (
        <div>
            <div className="container">
                <div className="wrapper">
                    <div className="wrapper-holder">
                        <div id="slider-img-1"></div>
                        <div id="slider-img-2"></div>
                    </div>
                </div>
                <div className="button-holder">
                    <a href="#slider-img-1" className="button">.</a>
                    <a href="#slider-img-2" className="button">.</a>
                </div>
            </div>
            <div className='qui-nous-sommes'>
                <a href='/Quisommenous'>Qui Sommes Nous ?</a>
                <a href='/Ton_Néon_sur_messure'>crée ton néon</a>
            </div>
            <div className="description-container">
                <div className="description-home">
                    <h3><strong>Néon sur mesure : créez votre pièce.</strong></h3>
                    <p>Du salon à la boutique, le néon s'invite partout ! Pour une ambiance chaleureuse :</p>
                    <p>Dans votre intérieur, un cadeau original pour un anniversaire, un mariage inoubliable ou une communication visuelle efficace pour votre entreprise.<br/>Nos néons personnalisables sont la solution idéale.</p>
                    <p>Texte, logo, dessin... laissez libre cours à votre imagination.</p>
                </div>
            </div>
            <div className="image-container-home">
                <img src={img1} alt="img-demo-néon"></img>
            </div>
            <div className="description-container">
                <div className="description-home">
                    <p>"Le bois rencontre la lumière. Chez AC custom, nous repoussons les limites de la Creation en marriant le charme authentique du chêne à la brillance du néon. Un contraste saisissant pour des pièces uniques"</p>
                </div>
            </div>
            <div className="image-container-home">
                <img src={img2} alt="img-néon-accustom"></img>
            </div>
            <div className="description-container">
                <div className="description-home">
                    <p>Pour une meilleur visibilité, vous pouvez également :</p>
                    <ul>
                        <li>Demandez votre devis gratuit</li>
                        <li>Configurez votre néon en ligne</li>
                        <li>Contacter notre équipe</li>
                    </ul>
                </div>
            </div>

            <div className="video_container">
                <video className="video" controls autoPlay loop muted>
                    <source src={videomp4} type="video/mp4"/>
                    <source src={videogg} type="video/ogg"/>
                    Votre navigateur ne supporte pas les vidéos HTML5.
                </video>
            </div>
        </div>
    );
}

export default Creation;