import "../Styles/information.css"

function Information() {
    return (
        <div className="information-home">
            <div>
                <h3><u>AC Custom by El-fy</u></h3>
                <p>est une entreprise spécialisée dans la création de néons sur-mesure. Nous sommes passionnés par l'art du néon et nous nous engageons à créer des pièces uniques et originales qui répondent à vos besoins et à vos envies.</p>
            </div>
            <div>
                <h3><u>Notre histoire</u></h3>
                <p>L’histoire de l’agence El-Fy commence en 2019, avec la création de notre entreprise d’évènementiel, s’en suit une forte demande sur de la personnalisation sur mesure pour des décors et des évènements. En découle la création de notre service AC Custom en 2021 spécialisé dans la personnalisation et principalement la création de néon unique.</p>
            </div>
            <div>
                <h3><u>Notre mission</u></h3>
                <p>est de vous aider à créer une ambiance unique et chaleureuse dans votre intérieur ou votre commerce. Nous travaillons en étroite collaboration avec nos clients pour concevoir des néons qui correspondent à leur style et à leur personnalité.</p>
            </div>

            <div>
                <h3><u>Nos valeurs</u></h3>
                <p>sont la créativité, la qualité et le service client. Nous sommes fiers de proposer des néons de la plus haute qualité, fabriqués avec des matériaux durables et des composants de première qualité. Nous sommes également fiers de notre service client exceptionnel, et nous nous engageons à vous offrir une expérience d'achat positive.</p>
            </div>

            <div>
                <h3><u>Nos services</u></h3>
                <p>Nous proposons une large gamme de services de création de néons sur-mesure, notamment :</p>
                <p>-Conception de néons : Nous pouvons créer un néon à partir de votre propre design ou vous proposer une sélection de modèles à choisir.</p>
                <p>-Fabrication de néons : Nous fabriquons tous nos néons dans notre propre atelier, en utilisant des techniques traditionnelles et des technologies de pointe. commerce.</p>
                <p>-Réparation de néons : Nous proposons également des services de réparation de néons.</p>
            </div>
        </div>
    );
}

export default Information;