import { useState } from 'react';
import '../Styles/render_néon.css';
import '../Styles/font.css';
import backimg from '../assets/wood.webp';
import { BsArrowsVertical } from "react-icons/bs";
import { BsArrows } from "react-icons/bs";
import Modal from './Modal';

function RenderNéon() {
    const [text, setText] = useState('');
    const [textColor, setColor] = useState('red');
    const [textFonts, setFont] = useState('Arial');
    const [textsize, setSize] = useState(0);
    const [textSupport, setSupport] = useState('plexiglass');
    const [typedecoupe, setDecoupe] = useState('Rectangle');
    const [tarif_ttc, setTarifTTC] = useState(0);
    const [font_r, setFont_r] = useState(0);
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState('');

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    let nb_line = 1;
    const maxline = 2;
    const count_line = () => {
        let n = 1;
        for (let i = 0; i < text.length; i++)
            if (text[i] === "\n")
                n++;
        return n;
    }
    if (text !== '')
        nb_line = count_line();

    let arrows_style = {
        justifyContent: nb_line === 1 ? '' : 'end',
        display: text === '' ? 'none' : 'flex',
    };
    let display_len = '';
    let paragraphStyle = {
        display: typedecoupe === 'Rectangle' ? 'none' : '',
        fontFamily: textFonts,
        WebkitTextStroke: window.innerWidth >= 1024 ? '12px' : '5px',
        color: textSupport !== 'bois' && typedecoupe === 'Contour' ? 'rgba(255, 255, 255, 0.20)': 'rgba(255, 255, 255, 0.0)',
        background: (textSupport === 'bois' && typedecoupe === 'Contour' ? `url(${backimg})` : 'none'),
        WebkitBackgroundClip: textSupport === 'bois' && typedecoupe === 'Contour' ? 'text' : 'initial',
        backgroundClip: textSupport === 'bois' && typedecoupe === 'Contour' ? 'text' : 'initial',
        WebkitTextFillColor: textSupport === 'bois' && typedecoupe === 'Contour' ? 'transparent' : 'initial',
        textFillColor: textSupport === 'bois' && typedecoupe === 'Contour' ? 'transparent' : 'initial',
        paddingRight: typedecoupe === 'Contour' ? '2rem' : '0',
        paddingLeft: typedecoupe === 'Contour' ? '2rem' : '0',
        textAlign: 'center',
    };
    let paragraphStylesp = {};
    let paragraphStyle2 = {
        display: 'flex',
        color: 'white',
        textShadow: `0 0 5px ${textColor}, 0 0 10px ${textColor}, 0 0 20px ${textColor}`,
        WebkitTextStroke: '0.3px' + textColor,
        fontFamily: textFonts,
        position: typedecoupe === 'Rectangle' ? 'none' : 'absolute',
        zindex: -1,
    };
    let paragraphStyle3 = {
        display: 'flex',
        background: textSupport === 'plexiglass' ? 'rgba(255, 255, 255, 0.25)' : 'url(' + backimg + ')',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        paddingBottom: '0.2rem',
        color: 'white',
        textShadow: `0 0 5px ${textColor}, 0 0 10px ${textColor}, 0 0 20px ${textColor}`,
        WebkitTextStroke: '0.3px' + textColor,
        fontFamily: textFonts,
    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const prix_ttc = (text_t, size, hauteur, decoupe) => {
        fetch('https://back-end-three-olive.vercel.app/tarif?longeur=' + size + '&hauteur=' + hauteur + '&message=' + encodeURIComponent(text_t) + '&line=' + nb_line + '&type=' + decoupe, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }}).then(async res => {
                let data = await res.json();
                data.prix = Math.round(data.prix);
                setTarifTTC(data.prix);
            }).catch((error) => {
                console.error('There was a problem with the GET request:', error);
            });
    }
    const soumettre = (event) => {
        event.preventDefault();
        fetch('https://back-end-three-olive.vercel.app/Devis', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                subject: "Demande de devis", 
                longueur: textsize,
                hauteur: font_r,
                fonts: textFonts,
                color: textColor,
                decoupe: typedecoupe,
                support: textSupport,
                text: text,
                email: email,
                tel: tel,
                prix: tarif_ttc,
                name: name,
            }),
            })
            .then((data) => {
                // async function slp() {
                //     await sleep(2000)
                //     for (let i = 0; i < 25; i++) {
                //         console.log('Server response:' + data.status);
                //         fetch('https://back-end-three-olive.vercel.app/loading',{
                //             method: 'GET',
                //             headers: {
                //                 'Content-Type': 'application/json',
                //             }
                //         })
                //     }
                // }
                // slp()
                handleCloseModal();
            })
            .catch((error) => {
                console.error('There was a problem with the POST request:', error);
            });
    }
    if (typedecoupe === 'Rectangle' && text !== '') {
        paragraphStylesp = paragraphStyle3;
    } else if (text !== '') {
        paragraphStylesp = paragraphStyle2;
    }
    if (text !== '') {
        display_len = 'flex';
    } else {
        display_len = 'none';
    }
    function getTextHeight(ctx, text) {
        let metrics = ctx.measureText(text);
        return metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    }
    function getDPI() {
        var div = document.createElement('div');
        div.style.width = '1in';
        div.style.position = 'absolute';
        div.style.top = '-100%';
        document.body.appendChild(div);
        var dpi = div.offsetWidth;
        document.body.removeChild(div);
        return dpi;
    }
    function pixelsToCm(pixels, dpi) {
        var inches = pixels / dpi;
        var cm = inches * 2.54;
        return cm;
    }
    function text_height(text_t, fonts, decoupe) {
        let canvas = document.getElementById('Canvas');
        let ctx = canvas.getContext('2d');
        ctx.font = '100px ' + fonts;
        document.fonts.ready.then(() => {
            if (nb_line === 1) {
                let textWidth = ctx.measureText(text_t).width;
                let textheight2 = getTextHeight(ctx, text_t);
                let dpi = getDPI();
                let textHeightCm = 0;
                let textWidthCm = 0;
                let multip = text_t.length;
                let n = 0;
                while (n !== 10) {
                    textHeightCm = (pixelsToCm(textheight2, dpi) * multip) + 2;
                    textWidthCm = (pixelsToCm(textWidth, dpi) * multip) + 2;
                    if (textHeightCm > 50 || textWidthCm > 100) {
                        multip /= 2;
                    } else {
                        if (textHeightCm < 12 && text_t !== '' && textWidthCm < 100) {
                            multip += 1;
                            textHeightCm = (pixelsToCm(textheight2, dpi) * multip) + 2;
                            textWidthCm = (pixelsToCm(textWidth, dpi) * multip) + 2;
                            if (textWidthCm >= 100 && textHeightCm <= 12)
                                break;
                        } else
                            break;
                    }
                    n++;
                }
                setFont_r(textHeightCm.toFixed(2));
                setSize(textWidthCm.toFixed(2));
                prix_ttc(text_t, textWidthCm.toFixed(2), textHeightCm.toFixed(2), decoupe);
            } else {
                let textWidth = [];
                let textHeight = [];
                let dpi = getDPI();
                text_t.split("\n").slice(0, maxline).map((row, index) => {
                    let textWrow = ctx.measureText(row).width;
                    let textHrow = getTextHeight(ctx, row);
                    let multip = row.length;
                    let n = 0;
                    let textHeightCm = 0;
                    let textWidthCm = 0;
                    while (n !== 10) {
                        textHeightCm = (pixelsToCm(textHrow, dpi) * multip) + 2;
                        textWidthCm = (pixelsToCm(textWrow, dpi) * multip) + 2;
                        if (textHeightCm > 50 || textWidthCm > 100) {
                            multip /= 2;
                        } else {
                            if (textHeightCm < 12 && text_t !== '' && textWidthCm < 100) {
                                multip += 1;
                                textHeightCm = (pixelsToCm(textHrow, dpi) * multip) + 2;
                                textWidthCm = (pixelsToCm(textWrow, dpi) * multip) + 2;
                                if (textWidthCm >= 100 && textHeightCm <= 12)
                                    break;
                            } else
                                break;
                        }
                        n++;
                    }
                    textWidth.push(textWidthCm);
                    textHeight.push(textHeightCm);
                    return 0;
                });
                let textWidthf = Math.max(...textWidth);
                let textHeightf = 0;
                for (let i = 0; i < textHeight.length; i++) {
                    textHeightf += textHeight[i];
                }
                setFont_r(textHeightf.toFixed(2));
                setSize(textWidthf.toFixed(2));
                prix_ttc(text_t, textWidthf.toFixed(2), textHeightf.toFixed(2), decoupe);
            }
        });
    }
    return (
        <div className='render-néon'>
            <div className='selected-prm'>
                <div className='prm-néon'>
                    <label>Typographies</label>
                    <select onChange={(e) => {setFont(e.target.value); text_height(text, e.target.value, typedecoupe)}} name="néon-fonts">
                        <option value="Arial">Arial</option>
                        <option value="Caveat">Caveat</option>
                        <option value="Economica">Economica</option>
                        <option value="Trebuchet MS">Trebuchet MS</option>
                        <option value="Dancing Script">Dancing Script</option>
                        <option value="EB Garamond">EB Garamond</option>
                        <option value="Shadows Into Light">Shadow into ligth</option>
                    </select>
                </div>
                <div className='prm-néon'>
                    <label htmlFor="color">Couleurs</label>
                    <select onChange={(e) => setColor(e.target.value)} name="néon-color">
                        <option value="red">Rouge</option>
                        <option value="white">Blanc</option>
                        <option value="blue">Bleu</option>
                        <option value="cyan">Bleu glacé</option>
                        <option value="pink">Rose</option>
                        <option value="lightpink">Rose clair</option>
                        <option value="yellow">Jaune</option>
                        <option value="gold">Jaune doré</option>
                        <option value="green">Vert</option>
                        <option value="orange">orange</option>
                        <option value="purple">violet</option>
                    </select>
                </div>
                <div className='prm-néon'>
                    <label>Choix du supports</label>
                    <select onChange={(e) => setSupport(e.target.value)} name="néon-fonts">
                        <option value="plexiglass">plexiglass (6mm)</option>
                        <option value="bois">bois de chéne (18mm)</option>
                    </select>
                </div>
                <div className='prm-néon'>
                    <label>Type de découpes</label>
                    <select onChange={(e) => {setDecoupe(e.target.value); text_height(text, textFonts, e.target.value)}} name="néon-fonts">
                        <option value="Rectangle">Rectangle</option>
                        <option value="Contour">Contour de lettre</option>
                    </select>
                </div>
            </div>
            <div className='input-text'>
                <textarea className='input-text' type="text" value={text} onChange={(e) => {setText(e.target.value); text_height(e.target.value, textFonts, typedecoupe)}} id="text" name="text" placeholder='Votre text'/>
            </div>
            <div className='back-box'>
                <div className='display-height' style={{display: display_len}}>
                    <h5>{font_r + ' cm'}</h5>
                    <BsArrowsVertical className='vertical-arrow'/>
                </div>
                <div className='back-box-2'>
                    <canvas id="Canvas" style={{display: 'none'}}></canvas>
                    <div className='display-res'>
                        {text.split("\n").slice(0, maxline).map((row, index) => {
                            return (
                            <div className='para-containeur' key={index}>
                                <p style={paragraphStyle}>{row}</p>
                                <p style={paragraphStylesp}>{row}</p>
                            </div>)
                        })}                        
                    </div>
                    <div className='display-width' style={arrows_style}>
                        <BsArrows className='horizontal-arrow' />
                        <h5>{textsize + " cm"}</h5>
                    </div>
                </div>
                <div className='display-price' style={{display: display_len}}>
                    <h5>{"Prix: " + tarif_ttc + '€ Ttc'}</h5>
                </div>
            </div>
            <div className='button-devis'>
            <button onClick={handleOpenModal}>Demander devis</button>
            <Modal show={showModal} handleClose={handleCloseModal}>
                <form onSubmit={soumettre}>
                    <p><b>Veuiller</b> renseigner votre email ainsi que votre telephone pour que nous puission vous recontacter</p>
                    <div className='form-modal'>
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder=' email' required/>
                        <input type="tel" value={tel} onChange={e => setTel(e.target.value)} placeholder=' Téléphone' required/>
                        <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder=' Nom Prénom' required/>
                        
                    </div>
                    <button type="submit">Demander devis</button>
                </form>
            </Modal>
            </div>
        </div>
    )
}

export default RenderNéon;
