import Navbar from '../components/navbar';
import '../Styles/TonNéon.css';
import Footer from '../components/Footer'
import FormulairePerso from '../components/Fom_perso';
import Sigle from "../components/Sigle"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { Helmet } from 'react-helmet';

function NéonPersonaliser() {
  return (
    <div className="App">
        <Helmet>
          <title>Formulaire Personalisation logo</title>
          <link rel="preload" href="../Styles/nav.css" as="style" />
        </Helmet>
        <Navbar/>
        <Sigle/>
        <h1 className='Title'><u>Ton logo, dessin,<br/>forme ici</u></h1>
        <FormulairePerso/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer/>
        <SpeedInsights/>
        <Analytics/>
    </div>
  );
}

export default NéonPersonaliser;
