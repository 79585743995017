import Navbar from '../components/navbar';
import '../Styles/TonNéon.css';
import Footer from '../components/Footer'
import Creation from "../components/Création"
import Sigle from "../components/Sigle"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div className="App">
        <Helmet>
          <meta name="description" content="Créer votre propre néon"/>
        </Helmet>
        <Navbar/>
        <Sigle/>
        <h1 className='Title'><u>Nos Créations</u></h1>
        <Creation/>
        <br/>
        <br/>
        <Footer/>
        <SpeedInsights/>
        <Analytics/>
    </div>
  );
}

export default Home;
