import { Routes, Route } from "react-router-dom";
import TonNéon  from "./screen/TonNéon";
import Home from "./screen/Home";
import NéonPersonaliser from "./screen/Néonpersonaliser";
import NosClassique from "./screen/NosClassique";
import Contact from "./screen/Contact";
import Nous from "./screen/Nous"
import Metion from "./screen/Mention"

function App() {
  return (
      <Routes>
        <Route path="/Ton_Néon_sur_messure" element={<TonNéon/>} />
        <Route path="/Ton_Néon_personaliser" element={<NéonPersonaliser/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/Nos_classique" element={<NosClassique/>}/>
        <Route path="/Contact" element={<Contact/>}/>
        <Route path="/Quisommenous" element={<Nous/>}/>
        <Route path="/Mentionlegal" element={<Metion/>}/>
      </Routes>
  );
}

export default App;
