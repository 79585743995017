import Navbar from '../components/navbar';
import '../Styles/TonNéon.css';
import Clasiques from '../components/Classiques';
import Footer from '../components/Footer'
import Sigle from "../components/Sigle"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { Helmet } from 'react-helmet';

function NosClassique() {
  return (
    <div className="App">
        <Helmet>
          <title>Nos Classiques</title>
          <meta name="description" content="Créer votre propre néon"/>
        </Helmet>
        <Navbar/>
        <Sigle/>
        <h1 className='Title'><u>Nos Classiques</u></h1>
        <Clasiques/>
        <Footer/>
        <SpeedInsights/>
        <Analytics/>
    </div>
  );
}

export default NosClassique;
