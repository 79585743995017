import "../Styles/Footer.css"
import CGV from "../assets/document/CGV_vente.pdf"

function Footer() {
    return (
        <footer>
            <div>
                <p>
                    2024 - Créé par <a href="https://www.linkedin.com/in/noa-vanhamme-5758072ab" target="blank" >Noa Vanhamme</a>
                    <br/>
                    <a href="/Mentionlegal">Mentions Légal </a> 
                    / 
                    <a href={CGV} target="blank"> CGV de ce service</a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;