import { useState } from 'react';
import "../Styles/Formulaire_perso.css"

function FormulairePerso() {
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [hauteur, setHauteur] = useState('');
    const [longueur, setLongueur] = useState('');
    const [name, setName] = useState('');
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const soumettre = (event) => {
        event.preventDefault();
        fetch('https://back-end-three-olive.vercel.app/DemandNeonPersonnaliser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                subject: "Commande Néon Personalisé",
                tel: tel,
                message: message,
                longueur: longueur,
                hauteur: hauteur,
                name: name,
            }),}).then((data) => {
                // async function slp() {
                //     await sleep(2000)
                //     console.log('Server response:' + data);
                //     for (let i = 0; i < 25; i++) {
                //         fetch('https://back-end-three-olive.vercel.app/loading',{
                //             method: 'GET',
                //             headers: {
                //                 'Content-Type': 'application/json',
                //             }
                //         })
                //     }
                // }
                // slp()
                setEmail('');
                setTel('');
                setMessage('');
                setHauteur('');
                setLongueur('');
                setName('');
            }).catch((error) => {
                console.error('There was a problem with the POST request:', error);
            });
    }
    return(
        <form className='formulaire-perso' onSubmit={soumettre}>
            <div className='form-messega-pers'>
                <textarea value={message} onChange={e => setMessage(e.target.value)} placeholder=' Décris nous simplement ce que tu veux (formes, couleur, etc...).' required/>
            </div>
            <div className='form-prm-1'>
                <input type="number" value={hauteur} onChange={e => setHauteur(e.target.value)} placeholder=' Hauteur (cm)'/>
                <input type="number" value={longueur} onChange={e => setLongueur(e.target.value)} placeholder=' Longueur (cm)'/>
            </div>
            <div className='form-prm-2'>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder=' email' required/>
                <input type="tel" value={tel} onChange={e => setTel(e.target.value)} placeholder=' Téléphone' required/>
                <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder=' Nom Prénom' required/>
            </div>
            <div className='form-prm-3'>
                <button type="submit">Soumettre</button>
            </div>
        </form>
    )
}

export default FormulairePerso;