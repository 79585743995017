import Navbar from '../components/navbar';
import RenderNéon from '../components/Render_Néon';
import Footer from '../components/Footer'
import '../Styles/TonNéon.css';
import Sigle from "../components/Sigle"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { Helmet } from 'react-helmet';

function TonNéon() {
    return (
        <div className="App">
            <Helmet>
                <title>Créer votre propre néon</title>
                <meta name="description" content="Créer votre propre néon"/>
            </Helmet>
            <Navbar/>
            <Sigle/>
            <h1 className='Title'><u>Néon sur mesure</u></h1>
            <RenderNéon/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Footer/>
            <SpeedInsights/>
            <Analytics/>
        </div>
    );
}

export default TonNéon;