import img2 from "../assets/classiques/new/boy-girl-h.webp";
import img3 from "../assets/classiques/new/boy-girl-l.webp";
import img5 from "../assets/classiques/new/I-love-you.webp";
import img7 from "../assets/classiques/new/coeur-love.webp";
import img8 from "../assets/classiques/new/lets.webp";
import img6 from "../assets/classiques/new/happy.webp";
import img4 from "../assets/classiques/new/mr-mme-2.webp";
import img10 from "../assets/classiques/new/wc.webp";
import img1 from "../assets/classiques/new/mr-mme-1.webp";
import img9 from "../assets/classiques/new/wc-femme-wc-homme.webp";

import "../Styles/Classique.css"

function Clasiques(){
    return (
        <div>
            <div className="image-néons" style={{marginBottom: '2rem', marginTop: '2rem'}}>
                <div className="img-contaner">
                    <img src={img5} alt="img_Love_you"/>
                    <p><b>Prix ttc :</b> 90€</p>
                </div>
                <div className="img-contaner">
                    <img src={img6} alt="img_happy"/>
                    <p><b>Prix ttc :</b> 200€</p>
                </div>
                <div className="img-contaner">
                    <img src={img3} alt="img_boy-girl-l"/>
                    <p><b>Prix ttc :</b> 230€</p>
                </div>
                <div className="img-contaner">
                    <img src={img7} alt="img_coeur-love"/>
                    <p><b>Prix ttc :</b> 135€</p>
                </div>
                <div className="img-contaner">
                    <img src={img8} alt="img_lets"/>
                    <p><b>Prix ttc :</b> 190€</p>
                </div>
                <div className="img-contaner">
                    <img src={img2} alt="img_boy-girl-h"/>
                    <p><b>Prix ttc :</b> 190€</p>
                </div>
                 
                <div className="img-contaner">
                    <img src={img4} alt="img_mr-mme-2"/>
                    <p><b>Prix ttc :</b> 130€</p>
                </div>
                <div className="img-contaner">
                    <img src={img1} alt="img-mr-mme-1"/>
                    <p><b>Prix ttc :</b> 190€</p>
                </div>
                <div className="img-contaner">
                    <img src={img9} alt="wc_femme-wc-homme"/>
                    <p><b>Prix ttc :</b> 170€</p>
                </div>
                <div className="img-contaner">
                    <img src={img10} alt="img_néon_wc"/>
                    <p><b>Prix ttc :</b> 150€</p>
                </div>
            </div>
            <div className="image-néon-link">
                <a href="/Contact">demande de devis </a>
            </div>
        </div>
    );
}

export default Clasiques;