import { useState } from 'react';
import '../Styles/Formulaire.css';

function Formulaire() {
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const soumettre = (event) => {
        event.preventDefault();
        fetch('https://back-end-three-olive.vercel.app/DemandeContact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                subject: "demande de contact",
                tel: tel,
                message: message,
                name: name, 
            })}).then((data) => {
                // async function slp() {
                //     await sleep(2000)
                //     console.log('Server response:' + data);
                //     for (let i = 0; i < 25; i++) {
                //         fetch('https://back-end-three-olive.vercel.app/loading',{
                //             method: 'GET',
                //             headers: {
                //                 'Content-Type': 'application/json',
                //             }
                //         })
                //     }
                // }
                // slp()
                setEmail('');
                setTel('');
                setMessage('');
                setName('');
            })
            .catch((error) => {
                console.error('There was a problem with the POST request:', error);
            });
    }
    return(
        <form className='formulaire' onSubmit={soumettre}>
          <div className='form-prm'>
            <label>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder=' email' required/>
            </label>
            <label>
                <input type="tel" value={tel} onChange={e => setTel(e.target.value)} placeholder=' Téléphone' required/>
            </label>
            <label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder=' Nom Prénom' required/>
            </label>
          </div>
          <div className='form-send'>
            <label>
                <textarea value={message} onChange={e => setMessage(e.target.value)} placeholder=' Message' required/>
            </label>
            <button type="submit">Soumettre</button>
          </div>
        </form>
    )
}

export default Formulaire;