import img from "../assets/Sigle.webp";
import "../Styles/Sigle.css"

function Sigle() {
    return (
        <div className="sigle-container">
            <img src={img} alt="img fabrication francaise"/>
        </div>
    )
}

export default Sigle;