import Navbar from '../components/navbar';
import '../Styles/TonNéon.css';
import Footer from '../components/Footer'
import "../Styles/Mention.css"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { Helmet } from 'react-helmet';

function Mention() {
    return (
        <div className="App">
            <Helmet>
                <title>Mention légal</title>
                <meta name="description" content="Créer votre propre néon"/>
            </Helmet>
            <Navbar/>
            <h1 className='Title'><u>Mention légal</u></h1>
            <div className='Mention'>
                <div className='Societe'>
                    <p>
                        <b>Raison Sociale :</b> SAS EL FY<br/>
                        <b>Siège Social :</b> 11 Rue des Muriers, 30660 Gallargues le Montueux<br/>
                        <b>Tel :</b> 06 23 32 60 30<br/>
                        <b>E-mail :</b> elfy.contact@gmail.com<br/>
                        <b>Siret :</b> 852 153 709 00013<br/>
                        <b>Propriétaire du contenu de site internet :</b> M ANASTASIO Cedric<br/><br/>
                    </p>
                </div>
                <div className='Societe'> 
                    <p><h2><b>CONDITIONS D'UTILISATION DU SITE</b></h2><br/>SAS EL FY ci dessus dénommée fournit ses services sous réserve que vous vous engagiez à respecter les présentes conditions d’utilisation que SAS EL FY ci dessus dénommée sera libre de modifier à tout moment.<br/><br/><br/></p>
                </div>
                <div className='Societe'>
                    <p><h2><b><u>Propriété intellectuelle</u></b></h2><br/>En accord avec les lois régissant la propriété intellectuelle, la reproduction ou l’utilisation des éléments se trouvant dans ce site Internet, en totalité ou en partie est strictement interdite. Seules sont maintenues les exceptions légales dont notamment la représentation dans le cadre du cercle de famille, la copie privée ou le droit de courte citation.
                    <br/>
                    Ainsi, tous les textes, photos, logos, marques et autres éléments reproduits sur ce site sont réservés et protégés par le droit de la propriété intellectuelle. (Droit auteur, droit voisin, droit des marques…).
                    <br/>
                    Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet est interdite et constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code de la propriété intellectuelle.
                    Il en est de même des éventuelles bases de données figurant sur le site Internet qui sont protégées par les dispositions de la loi du 11 juillet 1998 portant transposition dans le Code de la propriété intellectuelle (CPI) de la directive européenne du 11 mars 1996 relative à la protection juridique des bases de données.
                    <br/><br/>
                    </p>
                </div>
                <div className='Societe'>
                    <p><h2><b><u>Données personnelles</u></b></h2><br/>
                    SAS EL FY ci dessus dénommée s’engage à maintenir confidentielles les données personnelles fournies par l’utilisateur du site et à ne pas les transférer à d’autres sociétés ou organismes.<br/>
                    SAS EL FY ci dessus dénommée . s’engage à assurer à toute personne qui en fera la demande un droit d’accès, de rectification et de suppression des données la concernant.<br/><br/>
                    </p>
                </div>
                <div className='Societe'>
                    <p><h2><b><u>Responsabilité</u></b></h2><br/>
                    SAS EL FY ci dessus dénommée . décline toute responsabilité pour les difficultés rencontrées lors de l’accès à son site ou pour toute défaillance de communication.
                    <br/>
                    SAS EL FY ci dessus dénommée se réserve la possibilité de modifier, d’interrompre temporairement ou de façon permanente, toute ou une partie du site, sans préavis. SAS EL FY ci dessus dénommée ne pourra en aucun cas être tenue pour responsable à votre encontre ou à l’encontre d’un tiers de toute modification, interruption ou suspension du site.
                    <br/><br/>
                    </p>
                </div>
                <div className='Societe'>
                    <p><h2><b><u>Liens hypertextes</u></b></h2><br/>
                    Vous êtes libres d’établir des liens vers ce site dans la mesure où ils ne portent pas atteinte à l’image de son éditeur. Vous êtes autorisés à établir des liens vers les pages du site au format : html, php, xml.
                    <br/>
                    La pratique du framing est interdite de même que toute autre pratique ou technique susceptible d’engendrer une confusion dans l’esprit du public.
                    <br/>
                    Sont prohibés les liens profonds vers les documents aux formats pdf, Doc, ou rtf. En effet, ce type de document ne permet pas de prendre connaissance des présentes conditions d’utilisation.
                    <br/>
                    SAS EL FY ci dessus dénommée n’est en aucun cas responsable du contenu des sites Internet accessibles à travers son site web.
                    <br/><br/>
                    </p>
                </div>
            </div>
            <Footer/>
            <SpeedInsights/>
            <Analytics/>
        </div>
    );
}

export default Mention;